import React, { Component } from "react"
import 'react-tabs/style/react-tabs.css';
import Layout from "../components/layout-partnership";
import ReactReadMoreReadLess from "react-read-more-read-less";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import ScrollAnimation from 'react-animate-on-scroll';
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

const AutoplaySlider = withAutoplay(AwesomeSlider);

class PartnerTestimonials extends Component {
  componentDidMount() {
    var swiper = new Swiper('.universities-swiper', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 30,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          slidesPerColumn: 2,
          spaceBetween: 20,
          observer: true,
          observeParents: true,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerColumn: 2,
          spaceBetween: 30,
          observer: true,
          observeParents: true,
        }
      }
    });
    var swiper = new Swiper('.professors-swiper', {

      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        // bulletActiveClass: 'twinhealth-bullet'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
  }

  render() {
    const { partnerTestimonials} = this.props;
    function  stripHtml(html) {
      // Use a regular expression to remove HTML tags
      return html.replace(/<[^>]*>/g, '');
    }
    return (

      <div>
        <Layout>
          
          <div class="container">
            <div class="row">
              <div class="col-12">

                <ScrollAnimation 
                  animateIn='fadeIn'
                  style={{ 'animation-duration': '1s' }}
                >

                  <div class="title">
                    <h2 
                      class="wow fadeInUp" 
                      data-wow-duration="1s"
                      data-wow-delay=".7s"
                    >
                      {partnerTestimonials.partner_testimonials_title}
                      
                    </h2>
                  </div>

                </ScrollAnimation>
                
              </div>
            </div>
          </div>
        
          <div class="professors-sec">
            <div class="container"> 
              <div class="newest-clas"> 
                <div class="row"> 
                  <div class="col-12">

                    <ScrollAnimation 
                      animateIn='fadeIn'
                      style={{ 'animation-duration': '1s' }}
                    >

                      <AutoplaySlider
                        play={true}
                        interval={6000}
                      >

      {partnerTestimonials.partner_testimonials_detail.map((testimonial, index) => (
        <div className="swiper-slide" key={index}>
          <div className="professors-box">
            <div className="image-holder">
              <img
                src={testimonial.partner_testimonial_image.source_url}
                alt="Support"
                className="img-fluid"
              />
            </div>
            <div className="text-box">
              <h4>{testimonial.person_name}</h4>
              <span>{testimonial.person_designation}</span>
              <div>
                <ReactReadMoreReadLess
                  charLimit={300}
                  readMoreText={"  Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {stripHtml(testimonial.partner_testimonial)}
                </ReactReadMoreReadLess>
              </div>
            </div>
          </div>
        </div>
      ))}

 
                      </AutoplaySlider>

                    </ScrollAnimation>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default PartnerTestimonials;
